<script lang="ts">
	import type { BlockFieldsFragment } from '$lib/queries/fragments/generated/BlockFields'
	import type { PublicBlockFieldsFragment } from '$lib/queries/fragments/generated/PublicBlockFields'
	import MoveIcon from '$lib/components/icons/MoveIcon.svelte'
	import Spinner from '$lib/components/loading/Spinner.svelte'
	import { twMerge } from 'tailwind-merge'
	import { pluralize } from '$lib/utils/text-helpers'
	import { fixTitleCharacters } from '$lib/utils/title-case'
	import SkeletonBlock from '$lib/components/loading/SkeletonBlock.svelte'
	import { browser } from '$app/environment'
	import ExpandIcon from '$lib/components/icons/ExpandIcon.svelte'
	import BlockTemplate from './BlockTemplate.svelte'
	import { getCustomColorBgClassName } from '$lib/utils/custom-colors'
	import { getCustomIconSvgUrl } from '$lib/utils/custom-icons'
	import { BlockType } from '$lib/graphql/enums'
	import InViewObserver from '$lib/components/display/InViewObserver.svelte'
	import { stopPropagation } from '$lib/utils/clickHelpers'
	import { sanitizeAllTagsHtml, sanitizeHtml } from '$src/lib/utils/sanitize'

	interface Props {
		listBlock?: BlockFieldsFragment | PublicBlockFieldsFragment | null
		isImmutable?: boolean
		isPublic?: boolean
		numInList?: number
		iconUrl?: string
		bgColor?: string
		hasActions?: boolean
		isLoading?: boolean
		isBlockPositionUpdating?: boolean
		previewBlocks?: string[]
		expandedBlockId?: string | null
		childBlocks?: (BlockFieldsFragment | PublicBlockFieldsFragment)[]
		isMoving?: boolean
		isDndModal?: boolean
		openOnFirstClick?: boolean
		onclick?: (blockId: string) => void
		onexpand?: (blockId: string | null) => void
	}

	let {
		listBlock = null,
		isImmutable = false,
		isPublic = false,
		numInList = 0,
		iconUrl = '/visuals/icons/fork-knife.svg',
		bgColor = 'bg-red-200',
		hasActions = false,
		isLoading = false,
		isBlockPositionUpdating = false,
		previewBlocks = [],
		expandedBlockId = null,
		childBlocks = [],
		isMoving = false,
		openOnFirstClick = false,
		isDndModal = false,
		onexpand,
		onclick,
	}: Props = $props()

	let sanitizedDescription = $state('')
	let sanitizedExpandedDescription = $state('')

	function handleBlockClick() {
		if (isDndModal) return
		onclick?.(listBlock?.id)
	}

	function handleExpand() {
		if (openOnFirstClick) {
			onclick?.(listBlock?.id)
		} else {
			// console.log('handleExpand', isExpanded ? null : listBlock?.id)
			onexpand?.(isExpanded ? null : listBlock?.id)
		}
	}

	let isInView = $state(false)

	function handleEnter() {
		isInView = true
	}

	let isExpanded = $derived(expandedBlockId === listBlock?.id)
	$effect(() => {
		if (browser) {
			const description =
				listBlock?.description != '' ? fixTitleCharacters(listBlock?.description) : 'no description'

			sanitizedDescription = sanitizeAllTagsHtml(
				description && description != ''
					? fixTitleCharacters(description?.replace(/<\/p><p>/g, ' '))
					: 'no description',
				browser,
			)?.trim()

			sanitizedExpandedDescription = sanitizeHtml(description, browser)?.trim()
		}
	})
	let displayedDescription = $state('')
	$effect(() => {
		displayedDescription = isExpanded ? sanitizedExpandedDescription : sanitizedDescription
	})
	let fixedTitle = $state('')
	$effect(() => {
		fixedTitle = fixTitleCharacters(listBlock?.title)
	})
	// Calculate skeleton width based on title length
	let skeletonWidth = $derived(Math.min(100, Math.max(50, (fixedTitle?.length ?? 0) * 2)))
	let computedClassNameTitle = $derived(
		twMerge(
			'font-medium dark:text-brand-gray-2 text-black pr-2 dark:group-hover:text-white transition-all',
			isExpanded ? 'text-lg text-wrap break-all' : 'text-sm truncate w-full',
		),
	)
	let computedClassNameDescription = $derived(
		twMerge(
			'dark:text-brand-gray-3 text-brand-gray-5 pr-2 dark:group-hover:text-brand-gray-2 transition-all',
			isExpanded && isPublic
				? 'text-sm mt-2 text-wrap blur-small prose-spacing'
				: isExpanded
					? 'text-sm mt-2 text-wrap prose-spacing -mr-8'
					: 'text-xs truncate text-nowrap w-full',
		),
	)
	let computedClassNameBlocks = $derived(
		twMerge(
			'mt-1 italic text-[10px] dark:text-brand-gray-3 text-brand-gray-5 w-full pr-10 dark:group-hover:text-brand-gray-2 transition-all',
			isExpanded ? 'hidden' : 'truncate flex',
		),
	)
	let computedBorderClasses = $derived(
		twMerge(
			'group w-full overflow-hidden my-2 bg-white group dark:bg-brand-gray-5 border rounded-md flex justify-between items-start w-full transition-all',
			isExpanded ? 'dark:border-brand-gray-5' : 'dark:border-brand-gray-3/20',
		),
	)
	let computedIconClasses = $derived(
		twMerge(
			`flex flex-shrink-0 items-center justify-center w-8 rounded-l-[5px] -ml-[1px] -my-[1px]  transition-all ${bgColor}`,
			isExpanded ? 'h-8 rounded-[5px]' : 'h-[74px] ',
		),
	)
	let computedMainDetailsClasses = $derived(
		twMerge(
			'pl-3 text-start w-full flex flex-col items-start justify-between transition-all',
			isExpanded ? 'pr-8' : 'py-2 truncate',
		),
	)
</script>

<InViewObserver
	onenter={handleEnter}
	class="w-full flex flex-col items-start overflow-hidden"
	enabled={true}
	id={listBlock?.id}
>
	{#if isInView || !browser}
		<div class={computedBorderClasses}>
			<button
				onclick={handleExpand}
				class={`flex items-start justify-start w-full truncate ${isDndModal ? 'cursor-grab' : ''}`}
			>
				<div class={computedIconClasses}>
					<img class="h-5 w-5" src={iconUrl} alt={listBlock?.title} />
				</div>

				<div class={computedMainDetailsClasses}>
					{#if !browser}
						<SkeletonBlock width={`${skeletonWidth}%`} height="18px" class="mb-2" />
						<SkeletonBlock width="80%" height="14px" class="mb-2" />
						<SkeletonBlock width="60%" height="12px" />
					{:else}
						<div
							class={computedClassNameTitle}
							bind:innerHTML={fixedTitle}
							contenteditable="false"
						></div>
						<p
							class={computedClassNameDescription}
							bind:innerHTML={displayedDescription}
							contenteditable="false"
						></p>

						<p class={computedClassNameBlocks}>
							Contains {pluralize(numInList, 'item', 'items')}
						</p>
					{/if}
				</div>
			</button>

			{#if isDndModal}
				<div class="py-2 mr-1">
					{#if isLoading && isBlockPositionUpdating}
						<Spinner size="sm" embedded />
					{:else}
						<button class="hover:cursor-grab">
							<MoveIcon class="h-4 w-4 text-brand-gray-5 dark:text-brand-gray-2" />
						</button>
					{/if}
				</div>
			{:else if hasActions && !isExpanded}
				<div
					class={`items-center space-x-1 dark:bg-brand-gray-5 py-2 mr-1 ${
						isLoading && isBlockPositionUpdating ? 'flex' : 'hidden group-hover:flex'
					}`}
				>
					{#if isLoading && isBlockPositionUpdating}
						<Spinner size="sm" embedded />
					{:else}
						<button class="hover:cursor-grab">
							<MoveIcon class="h-4 w-4 text-brand-gray-5 dark:text-brand-gray-2" />
						</button>
						<button
							class="hover:cursor-pointer touch-manipulation"
							onclick={stopPropagation(handleBlockClick)}
						>
							<ExpandIcon class="h-4 w-4 text-brand-gray-5 dark:text-brand-gray-2" />
						</button>
					{/if}
				</div>
			{:else}
				<div
					class={`items-center space-x-1 dark:bg-brand-gray-5 py-2 mr-1 ${
						isExpanded ? 'flex' : 'hidden'
					}`}
				>
					<button
						class="hover:cursor-pointer flex items-center touch-manipulation"
						onclick={stopPropagation(handleBlockClick)}
					>
						<span class="mr-2 text-xs text-brand-gray-5 dark:text-brand-gray-2">more</span>
						<ExpandIcon class="h-4 w-4 text-brand-gray-5 dark:text-brand-gray-2" />
					</button>
				</div>
			{/if}
		</div>

		{#if !isMoving && !isLoading}
			<div class={`py-2 pl-10 truncate transition-all w-full ${isExpanded ? 'block' : 'hidden'}`}>
				{#each childBlocks ?? [] as block}
					{@const color = getCustomColorBgClassName(block?.color, block?.blockType)}
					{@const icon = getCustomIconSvgUrl(block?.icon, block?.blockType)}
					<BlockTemplate
						{previewBlocks}
						title={block?.title}
						description={block?.description}
						blockId={block?.id}
						{isPublic}
						{isImmutable}
						iconUrl={icon}
						bgColor={color}
						{expandedBlockId}
						openOnFirstClick={true}
						{onclick}
						{onexpand}
					/>
				{/each}
				{#each listBlock?.sources ?? [] as source}
					{@const color = getCustomColorBgClassName(source?.color, BlockType.Source)}
					{@const icon = getCustomIconSvgUrl(source?.icon, BlockType.Source)}
					<BlockTemplate
						title={source?.title}
						description={source?.description}
						imageUrl={source?.imageUrl != '' ? source?.imageUrl : null}
						bgColor={color}
						iconUrl={icon}
						blockId={source?.id}
						{isPublic}
						{onclick}
						{expandedBlockId}
						openOnFirstClick={true}
						{onexpand}
					/>
				{/each}
			</div>
		{/if}
	{/if}
</InViewObserver>
